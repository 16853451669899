import { ReactNode } from 'react';
import { classNames } from '../utils';
import { FAIcon } from '../utils/FAIcons';
import { FAIconProps } from '../utils/FAIcons/FAIconsProps';

type TabDefinition<T extends string | number> = {
	value: T;
	onClick?: () => void;
	tab: ReactNode;
	icon?: string;
	iconPrefix?: FAIconProps['prefix'];
	count?: number;
	isHidden?: boolean;
	important?: boolean;
};

type TabGroupProps<T extends string | number> = {
	tabs: TabDefinition<T>[];
	activeTab: T;
	onActiveTabChange?: (value: T) => void;
	column?: boolean;
	width?: string;
	colors?: {
		active: string;
		inactive: string;
		primaryBorder?: string;
	};
	size?: 'normal' | 'small' | 'extraSmall';
};

const TabGroup = <T extends string | number>({
	tabs,
	activeTab,
	onActiveTabChange,
	column = true,
	width,
	colors,
	size = 'normal',
}: TabGroupProps<T>) => {
	return (
		<div className={`border-b ${colors && colors.primaryBorder ? colors.primaryBorder : 'border-gray-300'}`}>
			<div
				className={classNames(
					'flex items-end w-full',
					size === 'normal' ? 'space-x-6 px-5' : '',
					size === 'small' ? 'space-x-3 px-2 text-sm' : '',
					size === 'extraSmall' ? 'space-x-2 px-1 text-xs' : ''
				)}
			>
				{tabs.map((item, index) => (
					<button
						key={`${item.value}-${index}`}
						className={width ? width : ''}
						onClick={() => {
							onActiveTabChange && onActiveTabChange(item.value);
							item.onClick && item.onClick();
						}}
						hidden={item.isHidden}
					>
						<a
							className={classNames(
								item.value === activeTab
									? colors && colors.active
										? colors.active
										: 'border-primary text-primary'
									: colors && colors.inactive
										? colors.inactive
										: 'text-gray-500 hover:border-gray-300 hover:text-gray-700 border-transparent',
								column ? 'flex-col' : 'space-x-2',
								'inline-flex items-center border-b-2 px-1 py-2 font-medium h-full w-full',
								item.important ? 'text-orange-600' : ''
							)}
						>
							{item.icon && <FAIcon className="px-2" icon={item.icon} prefix={item.iconPrefix} />}
							{item.tab}
							{!!item.count && ` (${item.count})`}
						</a>
					</button>
				))}
			</div>
		</div>
	);
};

export default TabGroup;
