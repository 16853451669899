import React, { ChangeEvent, Fragment, useEffect, useRef, useState } from 'react';
import { classNames, getFullName } from '~/components/utils';
import { FAIcon } from '~/components/utils/FAIcons';
import { trpc } from '@utils/trpc';
import { getJobBusinessCategoryIcon } from '@utils/iconHelpers';
import PhaseIdBadge from '~/components/pages/jobs/job-list/PhaseIdBadge';
import { highlightStringMatches, titlifyEnum } from '@utils/stringHelpers';
import { NoDataDisplay } from '~/components/NoDataDisplay';
import { LoadingScreen } from '~/components/LoadingScreen';
import { useRouter } from 'next/router';
import { BusinessCategory } from '@prisma/client';
import { useJobIndexContext } from '~/react_context/JobIndexContext';
import { useDebouncedValue } from '@mantine/hooks';
import Badge from '~/components/utils/Badge';

type JobSearchProps = {
	id?: string;
	disabled?: boolean;
	placeholder?: string;
};

const JobSearchComboBox = ({ id = 'globalJobSearch', disabled, placeholder }: JobSearchProps) => {
	const router = useRouter();

	const {
		filterBarContext: {
			globalJobSearch: [query, setQuery],
			categorySearch: [categorySearch, setCategorySearch],
		},
	} = useJobIndexContext();

	const queryInput = useRef<HTMLInputElement>(null);
	const menuContainer = useRef<HTMLDivElement>(null);

	const isFocusedSearch = !!query && query === categorySearch;

	//Is the combobox open
	const [isOpen, setIsOpen] = useState<boolean>(false);

	//The current job id that is focused via keyboard navigation
	const [keyFocusedId, setKeyFocusedId] = useState<number | undefined>(undefined);

	useEffect(() => {
		setIsOpen(!!query);
	}, [query]);

	const [debouncedQuery] = useDebouncedValue(query, 500);
	const isQueryDelayed = query !== debouncedQuery;
	const backendSearchQuery = trpc.jobs.globalJobSearch.useQuery({
		searchQuery: debouncedQuery,
	});
	const businessCategoriesWithJobs = backendSearchQuery.data ?? [];

	const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		setQuery(e.target.value);
	};

	const handleJobClick = (jobId: number) => {
		router.push(`/jobs/${jobId}`);
	};

	const handleQuickEstimateClick = (id: number) => {
		router.push(`/quick-estimate/view/${id}`);
	};

	const handleViewAllResultsClick = (category?: BusinessCategory) => {
		setIsOpen(false);
		if (category) {
			router.push(`/jobs/category/${category.toLowerCase()}`);
			setCategorySearch(query);
		} else {
			router.push(`/quick-estimate?search=${query}`);
		}
	};

	const allJobIds = businessCategoriesWithJobs.flatMap(([_category, jobs]) => jobs.map((job) => job.id));
	const handleQueryKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key == 'Enter') {
			e.preventDefault();
			if (keyFocusedId) {
				handleJobClick(keyFocusedId);
			}
		}
		if (e.key == 'ArrowDown') {
			if (allJobIds.length) {
				e.preventDefault();
				if (!keyFocusedId) {
					setKeyFocusedId(allJobIds[0]);
				} else {
					const currentJobIdx = allJobIds.findIndex((jobId) => jobId === keyFocusedId);
					if (currentJobIdx < allJobIds.length - 1) {
						setKeyFocusedId(allJobIds[currentJobIdx + 1]);
					}
				}
			}
		}
		if (e.key == 'ArrowUp') {
			if (allJobIds.length) {
				e.preventDefault();
				if (!keyFocusedId) {
					setKeyFocusedId(allJobIds[allJobIds.length - 1]);
				} else {
					const currentJobIdx = allJobIds.findIndex((jobId) => jobId === keyFocusedId);
					if (currentJobIdx > 0) {
						setKeyFocusedId(allJobIds[currentJobIdx - 1]);
					}
				}
			}
		}
	};

	useEffect(() => {
		function handleClickOutside(event) {
			if (menuContainer.current && !menuContainer.current.contains(event.target) && queryInput.current !== event.target) {
				setIsOpen(false);
				setQuery('');
			} else if (queryInput.current === event.target && !!query) {
				setIsOpen(true);
			}
		}

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [menuContainer]);

	return (
		<div className="w-full">
			<div className="relative whitespace-nowrap w-full flex justify-end">
				<input
					role="combobox"
					disabled={!!disabled}
					id={id}
					aria-controls={id + '-dropdown'}
					aria-expanded={isOpen}
					type="text"
					ref={queryInput}
					placeholder={placeholder ?? 'Search All Jobs'}
					value={query}
					className={`w-2/3 inline rounded-md shadow-sm  bg-white py-2 pl-3 pr-5 placeholder-gray-400 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm ${
						isFocusedSearch ? 'border-2 border-primary' : 'border border-gray-400'
					}`}
					onKeyDown={(e) => {
						e.key == 'Enter' && e.preventDefault();
					}}
					onKeyUp={handleQueryKeyPress}
					onChange={handleInputChange}
					autoComplete="off"
				/>
				<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
					<FAIcon
						icon={isOpen ? 'chevron-down' : isFocusedSearch ? 'eye' : 'magnifying-glass'}
						className={`${isFocusedSearch ? 'text-primary' : 'text-gray-400'}`}
					/>
				</div>
			</div>
			<div className="relative w-full pt-0.5">
				<div
					id={id + '-dropdown'}
					className={classNames(
						'absolute bg-white border-2 border-gray-200 rounded-md shadow-md z-50 w-full overflow-y-auto',
						!isOpen && 'hidden'
					)}
					ref={menuContainer}
				>
					{backendSearchQuery.isLoading || isQueryDelayed ? (
						<div className="my-5">
							<LoadingScreen header="Searching Your Jobs" subheader={`Hang Tight! We're searching all of your jobs.`} />
						</div>
					) : backendSearchQuery.isSuccess && !businessCategoriesWithJobs.length ? (
						<div className="my-5">
							<NoDataDisplay
								icon="briefcase"
								header="No Jobs Found"
								subheader={`Sorry, we couldn't find any jobs matching "${query}"`}
								actionButtonText="Clear Search"
								actionButtonOnClick={() => setQuery('')}
							/>
						</div>
					) : (
						backendSearchQuery.isSuccess &&
						!!businessCategoriesWithJobs.length &&
						businessCategoriesWithJobs.map(([category, jobs]) => {
							const { icon, rawColor } = getJobBusinessCategoryIcon(category);

							return (
								<div key={category}>
									<div className="py-1 px-2 inline-flex items-center bg-gray-100 text-gray-700 font-semibold text-xs w-full">
										<div className="h-5 w-5 inline-flex items-center justify-center mr-1">
											<FAIcon prefix="fad" icon={icon} style={{ color: rawColor }} />
										</div>
										<h3>{category}</h3>
									</div>
									{jobs.map((job, idx, { length: jobArrLength }) => {
										const isLast = idx === jobArrLength - 1;

										return (
											<Fragment key={job.id}>
												<button
													type="button"
													role="option"
													onClick={() => (job.businessCategory ? handleJobClick(job.id) : handleQuickEstimateClick(job.id))}
													className={classNames(
														keyFocusedId === job.id ? 'bg-glazier-blue-50' : 'hover:bg-glazier-blue-50',
														'relative py-2 px-3 w-full text-sm border-b'
													)}
												>
													<div className="flex items-center justify-between">
														<div className="inline-flex flex-col space-y-1 text-left">
															<h3 className="font-medium">{highlightStringMatches(job.name, query)}</h3>
															{!!job.jobPhase && <PhaseIdBadge jobId={job.id} jobPhase={job.jobPhase} />}
														</div>
														{job.inWarrantyUntil && (
															<div className="">
																<Badge colors="bg-orange-50 text-grey-500">
																	<p className="text-sm">
																		Under Warranty Until:{' '}
																		<span className="font-semibold">{job.inWarrantyUntil}</span>
																	</p>
																</Badge>
															</div>
														)}
														<div className="inline-flex flex-col items-end space-y-1">
															<div className="flex items-center space-x-2">
																<p className="text-sm text-gray-500">
																	{highlightStringMatches(job.customer.name, query)}
																</p>
																<div className="h-3 w-3 inline-flex items-center justify-center ml-1">
																	<FAIcon icon={job.customer.isCommercial ? 'building' : 'user'} />
																</div>
															</div>
															{!!job.jobOwner && (
																<div className="flex items-center space-x-2">
																	<p className="text-sm text-gray-500">
																		{highlightStringMatches(getFullName(job.jobOwner), query)}
																	</p>
																	<div className="h-3 w-3 inline-flex items-center justify-center ml-1">
																		<FAIcon icon="id-badge" />
																	</div>
																</div>
															)}
														</div>
													</div>
												</button>
												{isLast && jobArrLength < job.totalCategoryCount && (
													<button
														type="button"
														role="option"
														onClick={() => handleViewAllResultsClick(job.businessCategory)}
														className="relative py-2 px-3 w-full text-sm text-gray-500 font-medium flex items-center hover:bg-glazier-blue-50"
													>
														View All {job.totalCategoryCount} {titlifyEnum(category)} Results{' '}
														<FAIcon icon="arrow-up-right-from-square" className="ml-2 text-xs" />
													</button>
												)}
											</Fragment>
										);
									})}
								</div>
							);
						})
					)}
				</div>
			</div>
		</div>
	);
};

export default JobSearchComboBox;
