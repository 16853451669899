import { JobPhase } from '@prisma/client';
import Link from 'next/link';
import { FAIcon } from '~/components/utils/FAIcons';
import Popover from '~/components/utils/Popover';

export const CreateJobButton = () => {
	return (
		<Popover>
			<Popover.Button closedClassName="bg-primary text-white" openClassName="bg-primary-dark text-white">
				<FAIcon className="pr-2" icon="plus" />
				<span className="whitespace-nowrap">Create New</span>
			</Popover.Button>
			<Popover.Panel xPosition="right-0" zIndex="z-20">
				<div>
					<Link
						href={`/jobs/create/?type=${JobPhase.ESTIMATE}`}
						className="text-gray-700 hover:bg-gray-200 group flex items-center px-3 py-2"
					>
						<FAIcon icon="bullseye-arrow" className="mr-2 text-primary" />
						Bid
					</Link>
					<Link href={`/quick-estimate/create`} className="text-gray-700 hover:bg-gray-200 group flex items-center px-3 py-2">
						<FAIcon icon="bolt" className="mr-2 text-primary" />
						Quick Estimate
					</Link>
					<Link
						href={`/jobs/create/?type=${JobPhase.WORK_ORDER}`}
						className="text-gray-700 hover:bg-gray-200 group flex items-center px-3 py-2"
					>
						<FAIcon icon="hammer" className="mr-2 text-primary" />
						Won Job
					</Link>
				</div>
			</Popover.Panel>
		</Popover>
	);
};
